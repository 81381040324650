import React from 'react';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { PlainClientAPI, Link } from 'contentful-management';
import { TextInput, Card, Text, Tabs, Stack } from '@contentful/f36-components';
import { useFieldChangeHook } from '../Field.hooks';
import { updateEntryTags } from '../../utilities';

interface PageUrlFieldProps {
  sdk: FieldExtensionSDK;
  cma: PlainClientAPI;
}

const defaults = {
  page: '/pages/',
  pdp: '/products/',
  pcp: '/collections/'
};

const emptyDefaultTab = defaults.page;

const PageUrlField = ({ sdk, cma }: PageUrlFieldProps) => {
  const savedUrl = sdk.field.getValue() || '';
  const savedUrlParts = savedUrl.split('/');
  savedUrlParts.shift();
  const tabPath = savedUrlParts.shift();
  const restUrlPath = savedUrlParts.join('/');
  const defaultTab = tabPath
    ? `/${tabPath}/`.replaceAll('//', '/')
    : emptyDefaultTab;

  const [url, setUrl] = React.useState(restUrlPath ?? '');
  const [tab, setTab] = React.useState(defaultTab);

  sdk.window.startAutoResizer();

  React.useEffect(() => {
    const fullUrl = `${tab}${url}`.replaceAll('//', '/');

    // Check to make sure we aren't setting it to just the prefixed tab
    if (url && url !== '') {
      sdk.field.setValue(fullUrl);
    } else {
      sdk.field.setValue('');
    }
  }, [sdk, url, tab]);

  // On change of field, we make sure to add/remove the correct tags.
  useFieldChangeHook(sdk, async () => {
    let tags: Array<Link<'Tag'>> = sdk.entry.getMetadata()?.tags || [];
    tags = tags.filter(
      (tag) => ['pdp', 'pcp', 'page'].includes(tag.sys.id) === false
    );

    switch (tab) {
      case defaults.page:
        tags.push({ sys: { type: 'Link', linkType: 'Tag', id: 'page' } });
        break;
      case defaults.pcp:
        tags.push({ sys: { type: 'Link', linkType: 'Tag', id: 'pcp' } });
        break;
      case defaults.pdp:
        tags.push({ sys: { type: 'Link', linkType: 'Tag', id: 'pdp' } });
        break;
    }

    await updateEntryTags(cma, sdk, tags);
  });

  return (
    <>
      <Card marginBottom="spacingM">
        <Stack flexDirection="column">
          <Tabs onTabChange={setTab} defaultTab={defaultTab}>
            <Tabs.List>
              <Tabs.Tab panelId={defaults.page}>
                Page&nbsp;
                <Text fontSize="fontSizeS" fontColor="gray500">
                  /pages/:id
                </Text>
              </Tabs.Tab>
              <Tabs.Tab panelId={defaults.pcp}>
                Collection&nbsp;
                <Text fontSize="fontSizeS" fontColor="gray500">
                  /collections/:id
                </Text>
              </Tabs.Tab>
              <Tabs.Tab panelId={defaults.pdp}>
                Product&nbsp;
                <Text fontSize="fontSizeS" fontColor="gray500">
                  /products/:id
                </Text>
              </Tabs.Tab>
            </Tabs.List>
          </Tabs>
          <TextInput.Group>
            <TextInput
              isDisabled={true}
              value={tab}
              style={{ maxWidth: '150px' }}
            />
            <TextInput
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="womens-no-show-socks"
            />
          </TextInput.Group>
        </Stack>
      </Card>
    </>
  );
};

export default PageUrlField;
